.buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;

    @media screen and (max-width: 350px) {
    }
    button {
        color: #000;
        background: #f5f5f5;
        padding: 0.65rem 1rem;
        border-radius: 10px;
        font-size: 0.85rem;
        text-transform: capitalize;
        cursor: pointer;
        border: none;
        margin-top: 1rem;
        font-family: 'Poppins';

        &.active {
            background: #8c12f0;
            color: #f5f5f5;
        }
    }
}
.workImages {
    margin-top: 5rem;
    // display: grid;
    // grid-template-columns: repeat(3,1fr);
    gap: 1rem;

    .workImage {
        &:nth-child(2n) {
            .col {
                order: -1;
            }
        }

        a {
            transition: transform 0.3s ease;
            font-size: 0;

            &:hover {
                transform: scale(1.05);
            }
        }

        @media screen and(max-width: 800px) {
            display: flex;
            flex-direction: column;
            padding: 4rem;

            &:nth-child(2n) {
                .col {
                    order: unset;
                }
            }

            .btns {
                margin-top: 2rem !important;
            }
        }
        @media screen and(max-width: 600px) {
            padding: 0rem;
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        // max-width: 380px;
        // height: 250px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        margin-bottom: 10rem;
        .col {
            display: flex;
            flex-direction: column;

            p {
                margin-top: 1rem;
            }

            .btns {
                margin-top: auto;
                display: flex;
                gap: 1rem;
                a {
                    text-decoration: none;
                    color: #000;
                    background: #f5f5f5;
                    padding: 0.65rem 1.6rem;
                    border-radius: 10px;
                    font-size: 0.85rem;
                    text-transform: capitalize;

                    &:first-of-type {
                        background: #8c12f0;
                        color: #f5f5f5;
                    }
                    &:last-of-type {
                        background: #f5f5f5;
                    }
                }
            }
        }
        h3 {
            font-size: 2rem;
            margin-bottom: 1rem;
        }
        color: #f5f5f5;

        @media screen and(max-width:600px) {
            max-width: 350px;
        }

        img {
            // width: 100%;
            // height: 100%;
            aspect-ratio: 16/9;
            border-radius: 10px;
            // object-fit: cover;
        }

        .hoverLayer {
            background: #8c12f0;
            width: 100%;
            height: 100%;
            z-index: 5;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            gap: 1.5rem;
            justify-content: center;
            align-items: center;

            a {
                width: 40px;
                height: 40px;
                background: rgba(255, 255, 255, 0.25);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                svg {
                    color: #f5f5f5;
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.talk {
    margin-top: 3rem;
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    gap: 1rem;

    .talk_left {
        h3 {
            font-size: 1.5rem;

            span {
                font-size: 3.5rem;
                color: #8c12f0;

                @media screen and (max-width: 599px) {
                    font-size: 2rem;
                }

                @media screen and (max-width: 360px) {
                    font-size: 1.5rem;
                }
            }
        }
    }
    .talk_right {
        align-self: flex-end;
        margin-bottom: 1.5rem;
        background: #8c12f0;
        padding: 0.65rem 1rem;
        border-radius: 10px;
        font-size: 0.85rem;

        a {
            text-decoration: none;
            color: #f5f5f5;
            text-transform: capitalize;
        }
    }
}
